@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
/* font-family: "Lato", sans-serif;  */

:root{
  --primaryFont : "Lato", sans-serif;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  font-family: var(--primaryFont);
  background: #e6e6e6;
}

.logo{
  font-family: var(--primaryFont);
  font-size: 36px;
}
.header{
  padding: 12px 0px;
 background-color: #e6e6e6;
}
.alok-btn{
  background: linear-gradient(315deg,#60a5fa,#2563eb);
  padding: 10px 24px;
  text-decoration: none;
  color: #fff;
  border-radius: 12px;
  font-family: var(--primaryFont);
  outline: none;
  border: none;
}
.alok-btn:hover{
  background: linear-gradient(to top left,#60a5fa,#dc2626) !important;
}
.url-section{
  padding: 60px 0px;
  background-image: linear-gradient(269deg,#02c2fe,#db00ff 93.98%);
}
.title{
  font-family: var(--primaryFont);
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 24px;
}
.input{
  padding: 16px 24px;
  outline: none;
  border: none;
  border-radius: 12px 0px 0px 12px ;
  font-family: var(--primaryFont);
}
.btn-download{
  background:linear-gradient(180deg,#fb015b,#d2024d) !important;
  border-radius: 0px 12px 12px 0px;
  font-family: var(--primaryFont);
  font-weight: 700;
}
.cover-img img{
  max-width: 200px !important;
  margin-top: 16px;
  border-radius: 6px;
}
.w-200{
  width: 200px;
  background: linear-gradient(180deg,#fb015b,#d2024d)  !important;
}
.fs-18{
  font-size: 18px !important;
}
/* .after-api-fetch{
  display: none;
} */
@media screen and (max-width: 567px) {
  .logo{
    font-size: 24px;
  }
  .alok-btn{
    padding: 10px 15px;
  }
  .input, .btn-download{
    border-radius: 12px;
  }
  .btn-download{
    margin-top: 16px;
    width: 100%;
  }
  
}